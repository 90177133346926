import { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';

export const MEMBERS_AREA_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
const MY_ACCOUNT_APP_DEF_ID = '14cffd81-5215-0a7f-22f8-074b0e2401fb';

const installMembersArea = async (editorSDK) => {
  const isMAInstalled = await editorSDK.document.tpa.isApplicationInstalled(
    '',
    {
      appDefinitionId: MY_ACCOUNT_APP_DEF_ID,
    }
  );
  if (!isMAInstalled) {
    const appDefinitionId = MEMBERS_AREA_APP_DEF_ID;

    await editorSDK.document.application.install('', {
      appDefinitionId: MEMBERS_AREA_APP_DEF_ID,
      originInfo: {
        appDefinitionId,
      },
    });
  }
};

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  if (options.firstInstall) {
    await installMembersArea(editorSDK);
    await editorSDK.pages.add('', {
      title: 'Bazaar Feed',
      definition: {
        id: '',
        type: 'Page',
        components: [],
        data: {
          managingAppDefId: appDefId,
          pageUriSEO: 'bazaar',
        },
        componentType: 'mobile.core.components.Page',
      },
      shouldAddMenuItem: false,
      shouldNavigateToPage: false,
    });

    await editorSDK.pages.add('', {
      title: 'Bazaar Create Product',
      definition: {
        id: '',
        type: 'Page',
        components: [],
        data: {
          managingAppDefId: appDefId,
          pageUriSEO: 'bazaar-create-product',
        },
        componentType: 'mobile.core.components.Page',
      },
      shouldAddMenuItem: false,
      shouldNavigateToPage: true,
    });

    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: 'cd50928a-f52b-4eec-8d43-ae91e088b7dd',
      installationType: 'closed' as WidgetInstallationType,
      layout: {
        height: 768,
        width: 1366,
        x: 0,
        y: 100,
      },
    });

    await editorSDK.pages.add('', {
      title: 'Bazaar Product',
      definition: {
        id: '',
        type: 'Page',
        components: [],
        data: {
          managingAppDefId: appDefId,
          pageUriSEO: 'bazaar-product',
        },
        componentType: 'mobile.core.components.Page',
      },
      shouldAddMenuItem: false,
      shouldNavigateToPage: true,
    });

    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: '435f867f-a52f-4ae3-841a-2573c4404cb5',
      installationType: 'closed' as WidgetInstallationType,
      layout: {
        height: 768,
        width: 1366,
        x: 0,
        y: 100,
      },
    });
  }
};
