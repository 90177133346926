
  var editorScriptEntry = require('/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = null;
      const experimentsConfig = {"centralized":true,"scopes":[]};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};
      const defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'wix-bazaar',
        appDefId: 'f998bd62-3ba1-438e-8b31-34e729b70376',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/src/components/CreateProductPage/editor.controller.ts');

      const model_0 = require('/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/src/components/CreateProductPage/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "f998bd62-3ba1-438e-8b31-34e729b70376-fpvai";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"f998bd62-3ba1-438e-8b31-34e729b70376-fpvai": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "f998bd62-3ba1-438e-8b31-34e729b70376-fpvai", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);
  
      const userController_1 = require('/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/src/components/ProductPage/editor.controller.ts');

      const model_1 = require('/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/src/components/ProductPage/model.ts').default;

      const manifest_1 = userController_1 ? userController_1.default || userController_1 : {};
      if (!manifest_1.type) {
        manifest_1.type = "f998bd62-3ba1-438e-8b31-34e729b70376-fmqox";
      }
      if (!manifest_1.getExports && model_1) {
        manifest_1.getExports = () => ({"f998bd62-3ba1-438e-8b31-34e729b70376-fmqox": model_1.getExports()});
      }
      if (userController_1 && userController_1.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "f998bd62-3ba1-438e-8b31-34e729b70376-fmqox", getWidgetManifest: userController_1.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_1);
  
      const userController_2 = require('/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/src/components/Widget1/editor.controller.ts');

      const model_2 = require('/home/builduser/work/6f7fc1db4ca40065/packages/wix-bazaar/src/components/Widget1/model.ts').default;

      const manifest_2 = userController_2 ? userController_2.default || userController_2 : {};
      if (!manifest_2.type) {
        manifest_2.type = "f998bd62-3ba1-438e-8b31-34e729b70376-v2589";
      }
      if (!manifest_2.getExports && model_2) {
        manifest_2.getExports = () => ({"f998bd62-3ba1-438e-8b31-34e729b70376-v2589": model_2.getExports()});
      }
      if (userController_2 && userController_2.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "f998bd62-3ba1-438e-8b31-34e729b70376-v2589", getWidgetManifest: userController_2.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_2);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
